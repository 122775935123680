import React, { useState, useContext, useEffect } from 'react';
import { Text } from 'components/catalyst/text';
import { Card, CardHeader, CardContent, CardFooter } from 'components/ui/card';
import { Button } from 'components/ui/button';
import { Description, Field, Label } from 'components/catalyst/fieldset';
import { Textarea } from 'components/catalyst/textarea';
import { Listbox, ListboxLabel, ListboxOption } from 'components/catalyst/listbox';
import { AppContext } from 'contexts/AppContext';
import { toast } from 'sonner'; // Add this import

export default function FeedbackPage() {
  const { logGeneralFeedback } = useContext(AppContext); 
  const [selectedFeature, setSelectedFeature] = useState('general'); 
  const [feedbackText, setFeedbackText] = useState('');

  const handleFeatureChange = (feature) => {
    setSelectedFeature(feature);
  };

  const handleFeedbackChange = (e) => {
    setFeedbackText(e.target.value);
  };

  const handleSubmit = () => {
    if (!feedbackText.trim()) {
      toast("Feedback cannot be empty", {
        description: "Please provide your feedback before submitting.",
        action: {
          label: "Close",
          onClick: () => console.log("Close"),
        },
      });
      return;
    }

    console.log("SUBMIT");
    setFeedbackText('');
    logGeneralFeedback(selectedFeature, feedbackText);
    toast("Feedback submitted", {
      description: "Thank you for your feedback!",
      action: {
        label: "Close",
        onClick: () => console.log("Close"),
      },
    });
  };

  return (
    <div className="w-full border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <div className="flex flex-wrap w-full pt-4 justify-center my-8">
        <Card className="w-[600px]">
          <CardHeader>
            <Text><strong>Share your feedback with us below.</strong></Text>
          </CardHeader>
          <CardContent>
            <Listbox value={selectedFeature} onChange={handleFeatureChange} placeholder="Select a Feature">
              <ListboxOption value="general">
                <ListboxLabel>General</ListboxLabel>
              </ListboxOption>
              <ListboxOption value="reminders">
                <ListboxLabel>Agents</ListboxLabel>
              </ListboxOption>
              <ListboxOption value="notes">
                <ListboxLabel>Multi-Agents</ListboxLabel>
              </ListboxOption>
              <ListboxOption value="email_generator">
                <ListboxLabel>Learning</ListboxLabel>
              </ListboxOption>
              <ListboxOption value="insights">
                <ListboxLabel>Monitoring</ListboxLabel>
              </ListboxOption>
            </Listbox>
            <Field className="mt-6">
              <Label>Feedback</Label>
              <Description>Please describe the issue or feedback about the feature.</Description>
              <Textarea rows={8} value={feedbackText} onChange={handleFeedbackChange} />
            </Field>
          </CardContent>
          <CardFooter className="flex justify-end">
            <Button size="sm" className="ml-auto" onClick={handleSubmit}>
              Submit Feedback
            </Button>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}