import React, { useState } from 'react';
import { Check, ChevronDown, X, PenSquare, Rocket, Zap, MoreHorizontal } from 'lucide-react';
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";
import { Card, CardContent } from "components/ui/card";
import { ScrollArea } from "components/ui/scroll-area";
import { DialogClose } from 'components/ui/dialog';
import { toast } from 'sonner';
import { Alert, AlertDescription, AlertTitle } from "components/ui/alert";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Attachment, Folder } from './attachment'

const documents = [
  { title: "product_catalog_8.csv", size: '4gb', type:'Azure Data Store' },
  { title: "Report.docx", size: '1.5MB', type:'' },
  { title: "Presentation.pptx", size: '3MB', type:'' },
];

const folders = [
  { title: "Product PDFs", files: '65' },
  { title: "Supplier Manuals", files: '12' },
];

export default function CreateProductAgent() {
  const [purposeText, setPurposeText] = useState("Type here...");
  const [instructionsText, setInstructionsText] = useState("Type here...");
  const [selectedLLM, setSelectedLLM] = useState('OpenAI');

  const handleSelectLLM = (llm) => {
    setSelectedLLM(llm);
  };

  const handleDeploy = () => {
    toast(
      <div className="flex items-center">
        <span>Compliance Agent Successfully Deployed!</span>
        <Button size="sm" className="ml-4" onClick={() => toast.dismiss()}>Close</Button>
      </div>
    );
  };

  const actions = [
    "Call SRS pricing and availability API",
    "Trigger Slack channel webhook for out of stock products",
    "Dynamically create follow up chips",
    "Surface resources in chat (product cards, PDF's)"
  ];

  const handleSave = async (e) => {
    e.preventDefault();
    //handleClose()
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center">
          <span className="text-sm text-gray-500 mr-2">3 actions</span>
          <span className="text-sm text-gray-500 mr-2">Version 2.0</span>
        </div>
      </div>

      <ScrollArea className="flex-grow overflow-auto">
        <div className="pr-4 relative">
          <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-green-100"></div> {/* Vertical line */}
          
          {/* Purpose */}
          <div className="flex mb-8">
            <div className="flex-shrink-0 mr-4 relative z-10">
              <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center text-green-500">
                <Check size={20} />
              </div>
            </div>
            <div className="flex-grow">
              <h3 className="text-lg font-semibold">Purpose</h3>
              <span className="text-sm text-gray-600">Define the purpose of this agent.</span>
              <Textarea 
                className="w-full mt-3" 
                rows={5}
                value={purposeText}
                onFocus={() => setPurposeText(purposeText === "Type here..." ? "" : purposeText)}
                onBlur={() => setPurposeText(purposeText === "" ? "Type here..." : purposeText)}
                onChange={(e) => setPurposeText(e.target.value)}
              />
            </div>
          </div>

          {/* Instructions */}
          <div className="flex mb-8">
            <div className="flex-shrink-0 mr-4 relative z-10">
              <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center text-green-500">
                <Check size={20} />
              </div>
            </div>
            <div className="flex-grow">
              <h3 className="text-lg font-semibold">Instructions</h3>
              <p className="text-sm text-gray-600">Describe the tasks that the agent will accomplish.</p>
              <Textarea 
                className="w-full mt-3" 
                rows={5}
                value={instructionsText}
                onFocus={() => setInstructionsText(instructionsText === "Type here..." ? "" : instructionsText)}
                onBlur={() => setInstructionsText(instructionsText === "" ? "Type here..." : instructionsText)}
                onChange={(e) => setInstructionsText(e.target.value)}
              />
            </div>
          </div>

          {/* Choose LLM */}
          <div className="flex mb-8">
            <div className="flex-shrink-0 mr-4 relative z-10">
              <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center text-green-500">
                <Check size={20} />
              </div>
            </div>
            <div className="flex-grow">
              <h3 className="text-lg font-semibold ">Choose LLM</h3>
              <p className="text-gray-600 text-sm">Select the Large Language Model (LLM) that will support your agent.</p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-3">
                <Card className={`p-4 ${selectedLLM === 'OpenAI' ? 'border-2 border-green-500' : ''}`} onClick={() => handleSelectLLM('OpenAI')}>
                  <h4 className="font-semibold">OpenAI/Azure GPT 4o, GPT4</h4>
                  <p className="text-sm text-gray-500">Provide OpenAI API key and complete validation</p>
                  <div className="mt-2 flex justify-between items-center">
                    <img src="/img/00.png" alt="OpenAI logo" className="w-8 h-8" />
                    {selectedLLM === 'OpenAI' && <Check size={20} className="text-green-500" />}
                  </div>
                </Card>
                <Card className={`p-4 ${selectedLLM === 'Claude' ? 'border-2 border-green-500' : ''}`} onClick={() => handleSelectLLM('Claude')}>
                  <h4 className="font-semibold">Claude Sonnet</h4>
                  <p className="text-sm text-gray-500">Provide API key and complete validation</p>
                  <div className="mt-2 flex justify-between items-center">
                    <img src="/img/01.png" alt="Azure logo" className="w-8 h-8" />
                    {selectedLLM === 'Claude' && <Check size={20} className="text-green-500" />}
                  </div>
                </Card>
                <Card className={`p-4 ${selectedLLM === 'Mistral' ? 'border-2 border-green-500' : ''}`} onClick={() => handleSelectLLM('Mistral')}>
                  <h4 className="font-semibold">Mistral</h4>
                  <p className="text-sm text-gray-500">Provide API key and complete validation</p>
                  <div className="mt-2 flex justify-between items-center">
                    <img src="/img/02.webp" alt="Ollama logo" className="w-8 h-auto" />
                    {selectedLLM === 'Mistral' && <Check size={20} className="text-green-500" />}
                  </div>
                </Card>
              </div>
            </div>
          </div>

          {/* Actions */}
          <div className="flex mb-8">
            <div className="flex-shrink-0 mr-4 relative z-10">
              <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center text-green-500">
                <Check size={20} />
              </div>
            </div>
            <div className="flex-grow">
              <h3 className="text-lg font-semibold">Actions</h3>
              <div className="flex justify-between items-center mb-4">
                <span className="text-gray-600 text-sm">Apply pre-configured or build a new action from the Action Gallery</span>
                <Button variant="outline" size="sm">+ Add more actions</Button>
              </div>
              {actions.map((action, index) => (
                <Alert key={index} className="flex justify-between items-center mt-2">
                  <div className="flex items-center">
                    <Zap className="h-4 w-4" />
                    <div className="ml-2">
                      <AlertTitle>Action</AlertTitle>
                      <AlertDescription>
                        {action}
                      </AlertDescription>
                    </div>
                  </div>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" className="h-8 w-8 p-0">
                        <span className="sr-only">Open menu</span>
                        <MoreHorizontal className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem>Edit action</DropdownMenuItem>
                      <DropdownMenuItem>Remove action</DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </Alert>
              ))}
            </div>
          </div>

          {/* Data */}
          <div className="flex">
            <div className="flex-shrink-0 mr-4 relative z-10">
              <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center text-green-500">
                <Check size={20} />
              </div>
            </div>
            <div className="flex-grow">
              <h3 className="text-lg font-semibold">Data</h3>
              <p className="text-sm text-gray-600">Upload and configure data sources.</p>
              <div className="flex flex-wrap gap-4 mt-4">
                {folders.map((folder, index) => (
                  <Folder key={index} folder={folder} />
                ))}
                {documents.map((document, index) => (
                  <Attachment key={index} document={document} />
                ))}
                <Button onClick={(e) => handleSave(e)} size="sm" className="ml-0 mt-1 h-[35px] w-[35px] rounded-full flex items-center justify-center" variant="outline">
                  +
                </Button>
              </div>
            </div>
          </div>

        </div>
      </ScrollArea>

      <div className="mt-6 flex justify-end">
        <DialogClose asChild>
          <Button size="lg" className="text-white text-[15px] font-semibold" onClick={handleDeploy}>
            <Rocket className='w-4 h-4 mr-2' />
            Deploy
          </Button>
        </DialogClose>
      </div>
    </div>
  );
}