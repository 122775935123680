import React, { useContext } from 'react';
import { AppContext } from 'contexts/AppContext';
import { X, FileText, FolderIcon, ChevronDown } from 'lucide-react'; 

export function Attachment({ document }) {
  return (
    <div className='shadow-sm flex items-center bg-gray-100 justify-between gap-1 rounded-lg border py-1 px-2 text-left text-sm transition-all hover:bg-accent min-w-[200px] max-w-[300px]'> 

      {/* File Icon */}
      <div className="flex items-center pr-2">
        <FileText className="w-5 h-5 text-gray-500" />
      </div>
      
      {/* File Information */}
      <div className="flex flex-col flex-grow overflow-hidden"> 
        <div className="font-semibold truncate max-w-full"> 
          {document.title}
        </div>
        <div className="text-xs text-gray-500">{document.size}</div>
      </div>
      
      {/* Remove Button */}
      <button className="flex-shrink-0 focus:outline-none">
        <X className="w-5 h-5" />
      </button>
    </div>
  );
}

export function Folder({ folder }) {
  return (
    <div className='shadow-sm flex items-center bg-gray-100 justify-between gap-1 rounded-lg border py-1 px-2 text-left text-sm transition-all hover:bg-accent min-w-[200px] max-w-[300px]'> 

      {/* Folder Icon */}
      <div className="flex items-center pr-2">
        <FolderIcon className="w-5 h-5 text-yellow-500" />
      </div>

      {/* Folder Information */}
      <div className="flex flex-col flex-grow overflow-hidden"> 
        <div className="font-semibold truncate max-w-full"> 
          {folder.title}
        </div>
        <div className="text-xs text-gray-500">{folder.files} files</div>
      </div>

      {/* Chevron Down Icon */}
      <button className="flex-shrink-0 focus:outline-none">
        <ChevronDown className="w-5 h-5" />
      </button>
    </div>
  );
}