import React, { useState, useContext, useEffect } from 'react';
import { Text } from 'components/catalyst/text';
import { Card, CardHeader, CardContent, CardFooter } from 'components/ui/card';
import { Button } from 'components/ui/button';
import { Description, Field, Label } from 'components/catalyst/fieldset';
import { Textarea } from 'components/catalyst/textarea';
import { Listbox, ListboxLabel, ListboxOption } from 'components/catalyst/listbox';
import { AppContext } from 'contexts/AppContext';
import { toast } from 'sonner'; // Add this import
import History from './learning';
import Learning from './learning';

export default function LearningPage() {
  return (
    <div className="w-full border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <div className="flex flex-wrap w-full pt-4 justify-center my-2">
        <Learning />
      </div>
    </div>
  );
}