import { CheckCircle, Pause, Square, AlertTriangle, MoreVertical, User, Users } from "lucide-react"
import { Button } from "components/ui/button"
import { Card, CardContent } from "components/ui/card"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu"

export default function Component() {
  const agents = [
    { name: "Agent 1", description: "Agent Description", status: "Normal" },
    { name: "Agent 2", description: "Agent Description", status: "Paused" },
    { name: "Agent 3", description: "Agent Description", status: "Stopped" },
    { name: "Agent 4", description: "Agent Description", status: "Error" },
  ]

  const multiAgents = [
    { name: "Multi-Agent 1", description: "Agent Description", status: "Normal" },
    { name: "Multi-Agent 2", description: "Agent Description", status: "Paused" },
    { name: "Multi-Agent 3", description: "Agent Description", status: "Stopped" },
    { name: "Multi-Agent 4", description: "Agent Description", status: "Error" },
  ]

  const StatusIcon = ({ status }) => {
    switch (status) {
      case "Normal":
        return <CheckCircle className="h-5 w-5 text-green-500" />
      case "Paused":
        return <Pause className="h-5 w-5 text-yellow-500" />
      case "Stopped":
        return <Square className="h-5 w-5 text-red-500" />
      case "Error":
        return <AlertTriangle className="h-5 w-5 text-pink-500" />
      default:
        return null
    }
  }

  const AgentCard = ({ agent, isMultiAgent }) => (
    <Card className="h-full">
      <CardContent className="flex flex-col justify-between h-full p-4">
        <div className="flex items-start space-x-4">
          <div className="bg-secondary p-2 rounded">
            {isMultiAgent ? (
              <Users className="h-6 w-6" />
            ) : (
              <User className="h-6 w-6" />
            )}
          </div>
          <div>
            <h3 className="font-semibold">{agent.name}</h3>
            <p className="text-sm text-muted-foreground">{agent.description}</p>
          </div>
        </div>
        <div className="flex items-center justify-between mt-4">
          <div className="flex items-center space-x-2">
            <span className="text-sm font-semibold">Status: {agent.status}</span>
            <StatusIcon status={agent.status} />
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon">
                <MoreVertical className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem>See Agent Details</DropdownMenuItem>
              <DropdownMenuItem>Pause Agent</DropdownMenuItem>
              <DropdownMenuItem>Stop Agent</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </CardContent>
    </Card>
  )

  return (
    <div className="container mx-auto p-4">
      <section className="mb-12">
        <h2 className="text-2xl font-bold mb-2">Current Status: Agents</h2>
        <p className="text-muted-foreground mb-4">Monitor status of active agents</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {agents.map((agent, index) => (
            <AgentCard key={index} agent={agent} isMultiAgent={false} />
          ))}
        </div>
      </section>
      <section>
        <h2 className="text-2xl font-bold mb-2">Current Status: Multi-Agents</h2>
        <p className="text-muted-foreground mb-4">Monitor status of active multi-agents</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {multiAgents.map((agent, index) => (
            <AgentCard key={index} agent={agent} isMultiAgent={true} />
          ))}
        </div>
      </section>
    </div>
  )
}