import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AppContext } from 'contexts/AppContext';
import { getAccessToken } from 'utils/authUtils'

const AuthGuard = ({ children }) => {
  const { testing } = useContext(AppContext);
  if (testing) return children

  const accessToken = getAccessToken(false);
  if (accessToken && (accessToken!='TestingAccessToken')) {
    console.log("Access Token Found: ", accessToken);
    return children;
  }

  const { authState, oktaAuth } = useOktaAuth();

  console.log("authState",authState)
  const isAuthenticated = useMemo(() => authState?.isAuthenticated, [authState]);
  const isLoading = useMemo(() => !authState, [authState]);

  useEffect(() => {
    console.log("authenticated",isAuthenticated,isLoading)
    if (!isAuthenticated && !isLoading) {
      oktaAuth.signInWithRedirect();
    }
  }, [isAuthenticated, isLoading, oktaAuth]);

  if (isLoading || !isAuthenticated) return null;

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(AuthGuard);
