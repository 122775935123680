import React, { useState, useEffect, useContext } from 'react';
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbPage, BreadcrumbSeparator } from 'components/ui/breadcrumb';
import { BellDot, UserIcon, HomeIcon, ZoomInIcon, Settings2Icon, BookText } from 'lucide-react';
import { Heading } from 'components/catalyst/heading';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import Cosailor from 'components/chat';
import { Link, useLocation } from 'react-router-dom';
import { menuItems } from './MenuItems';
import { DataContext } from 'contexts/DataContext';
import { NotificationDropdown } from 'components/catalyst/notifications';
import { CustomerSettings } from 'components/catalyst/customer-settings';

export const Header = () => {
  const location = useLocation();
  const { customerProfilePage } = useContext(DataContext);

  const [title, setTitle] = useState('');

  // Find the current menu item based on the location path
  const currentMenuItem = menuItems.find(item => item.path === location.pathname) || {};

  // Check if the current path matches "/customer/:id"
  const customerPathMatch = location.pathname.match(/^\/customer\/(.+)/);
  const customerId = customerPathMatch ? customerPathMatch[1] : null;

  useEffect(() => {
    if (customerId) {
      setTitle(customerProfilePage.custname || '');
    } else if (location.pathname === '/profile') {
      setTitle('My Profile');
    } else if (location.pathname === '/feedback') {
      setTitle('Feedback');
    } else {
      setTitle(currentMenuItem.page_title || '');
    }
  }, [customerProfilePage, currentMenuItem.page_title, location.pathname]);

  return (
    <header>
      <div>
        <Breadcrumb>
          <BreadcrumbList className="flex items-center">
            <Link to="/">
              <BreadcrumbItem className="flex items-center">
                <HomeIcon className="w-3.5" />
                <BreadcrumbPage>Home</BreadcrumbPage>
              </BreadcrumbItem>
            </Link>
            {customerId ? (
              <>
                <BreadcrumbSeparator />
                <Link to={`/customer/${customerId}`}>
                  <BreadcrumbItem className="flex items-center">
                    <UserIcon className="w-3.5" />
                    <BreadcrumbPage>Customer Profile</BreadcrumbPage>
                  </BreadcrumbItem>
                </Link>
                <BreadcrumbSeparator />
                <Link to={`/customer/${customerId}`}>
                  <BreadcrumbItem className="flex items-center">
                    <ZoomInIcon className="w-3.5" />
                    <BreadcrumbPage>{customerId}</BreadcrumbPage>
                  </BreadcrumbItem>
                </Link>
              </>
            ) : location.pathname === '/profile' ? (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem className="flex items-center">
                  <UserIcon className="w-3.5" />
                  <BreadcrumbPage>My Profile</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            ) : location.pathname === '/feedback' ? (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem className="flex items-center">
                  <Settings2Icon className="w-3.5" />
                  <BreadcrumbPage>Feedback</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            ) : (
              location.pathname !== '/' && (
                <>
                  <BreadcrumbSeparator />
                  <Link to={currentMenuItem.path}>
                    <BreadcrumbItem className="flex items-center">
                      {currentMenuItem.icon && <currentMenuItem.icon className="w-3.5" />}
                      <BreadcrumbPage>{currentMenuItem.page_title}</BreadcrumbPage>
                    </BreadcrumbItem>
                  </Link>
                </>
              )
            )}
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 pt-2 dark:border-white/10">
        <span className="text-[28px] font-extrabold">{title}</span>
        <div className="flex items-center ml-auto space-x-4">
          {/* 
          <Popover>
            <PopoverTrigger><Settings2Icon className="w-6 h-6" /></PopoverTrigger>
            <PopoverContent>
              <CustomerSettings />
            </PopoverContent>
          </Popover>
          */}
          <Link to="/feedback">
            <BookText />
          </Link>
          {/* <Popover>
            <PopoverTrigger><BellDot className="w-6 h-6" /></PopoverTrigger>
            <PopoverContent>
              <NotificationDropdown />
            </PopoverContent>
          </Popover> */}
          {/* <Cosailor /> */}
        </div>
      </div>
    </header>
  );
};