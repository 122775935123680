import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AppContext } from 'contexts/AppContext';

export const RemindersContext = createContext();

export const RemindersProvider = ({ children }) => {
  const { salesAgentCD,custcd , BASE_URL, oktaId } = useContext(AppContext);

  const [reminders, setReminders] = useState([]);

  const fetchReminders = async (custcd = 'none') => {
    if (!salesAgentCD) return
    try {
      const response = await axios.get(`${BASE_URL}/get-all-reminders`, {
        params: { salesagentcd: salesAgentCD, custcd: custcd, oktaid: oktaId }
      });
      setReminders(response.data);
      //console.log("REMINDERS",salesAgentCD,response.data)
    } catch (err) {
      setReminders([]);
      console.log("Problem with fetchReminders",err)
    }
  };

  useEffect(() => {
    fetchReminders('none')
  }, [salesAgentCD,custcd]);

  const createReminder = async (reminder) => {
    //if (isAdmin) return
    try {
      const response = await axios.post(`${BASE_URL}/create-reminder`, {...reminder, oktaid: oktaId});
      fetchReminders('none')
    } catch (err) {
      console.log(err)
    }
  };

  const updateReminder = async (reminder) => {
    //if (isAdmin) return
    try {
      const response = await axios.post(`${BASE_URL}/update-reminder`, {...reminder, oktaid: oktaId});
      fetchReminders('none')
      //setReminders(reminders.map(r => r.remindersid === reminder.remindersid ? response.data : r));
    } catch (err) {
      console.log(err)
    }
  };

  const deleteReminder = async (remindersid) => {
    //if (isAdmin) return
    try {
      await axios.post(`${BASE_URL}/delete-reminder`, { remindersid, oktaid: oktaId });
      fetchReminders('none')
      //setReminders(reminders.filter(r => r.remindersid !== remindersid));
    } catch (err) {
      console.log(err)
    }
  };

  const markReminderViewed = async (remindersid) => {
    //if (isAdmin) return
    try {
      await axios.post(`${BASE_URL}/reminder-viewed`, { remindersid, oktaid: oktaId });
      //fetchReminders('none')
      setReminders(reminders.map(r => r.remindersid === remindersid ? { ...r, viewed: true } : r));
    } catch (err) {
      console.log(err)
    }
  };

  const markReminderCompleted = async (remindersid, date_completed) => {
    //if (isAdmin) return
    try {
      await axios.post(`${BASE_URL}/reminder-completed`, { remindersid, date_completed, oktaid: oktaId });
      //fetchReminders('none')
      setReminders(reminders.map(r => r.remindersid === remindersid ? { ...r, date_completed } : r));
    } catch (err) {
      console.log(err)
    }
  };

  const archiveReminder = async (remindersid) => {
    //if (isAdmin) return
    try {
      await axios.post(`${BASE_URL}/archive-reminder`, { remindersid, oktaid: oktaId });
      fetchReminders('none')
      //setReminders(reminders.map(r => r.remindersid === remindersid ? { ...r, archived: true } : r));
    } catch (err) {
      console.log(err)
    }
  };

  const unarchiveReminder = async (remindersid) => {
    //if (isAdmin) return
    try {
      await axios.post(`${BASE_URL}/remove-archive-reminder`, { remindersid, oktaid: oktaId });
      fetchReminders('none')
      //setReminders(reminders.map(r => r.remindersid === remindersid ? { ...r, archived: false } : r));
    } catch (err) {
      console.log(err)
    }
  };

  const draftReminder = async (reminder) => {
    //if (isAdmin) return
    try {
      await axios.post(`${BASE_URL}/draft-reminder`, {...reminder, oktaid: oktaId});
      fetchReminders('none')
      //setReminders(reminders.map(r => r.remindersid === remindersid ? { ...r, draft: true } : r));
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <RemindersContext.Provider value={{
      reminders,fetchReminders,createReminder,updateReminder,deleteReminder,markReminderViewed,markReminderCompleted,
      archiveReminder,unarchiveReminder,draftReminder}}>
      {children}
    </RemindersContext.Provider>
  );
};

