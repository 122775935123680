// MenuItems.js
import { HomeIcon, SparklesIcon, CheckCircleIcon, Square3Stack3DIcon, PencilSquareIcon } from '@heroicons/react/20/solid';
import { Home, Monitor, Activity, User2Icon, ActivityIcon, Users, User, Workflow, SquareLibrary } from 'lucide-react';

export const menuItems = [
  {
    path: '/',
    label: 'Composable Agents',
    page_title: 'Composable Agents',
    icon: Users,
  },
  /* {
    path: '/composable',
    label: 'Composable Agents',
    page_title: 'Composable Agents',
    icon: Workflow,
  },   */
  {
    path: '/learning',
    label: 'Learning',
    page_title: 'Learning',
    icon: SquareLibrary,
  }, 
  /*
  {
    path: '/agents',
    label: 'Agents',
    page_title: 'Agents',
    icon: User2Icon,
  },
  */
  {
    path: '/monitoring',
    label: 'Monitoring',
    page_title: 'Monitoring',
    icon: ActivityIcon,
  }, 
  /*
  {
    path: '/email',
    label: 'Email Generator',
    page_title: 'Email Generator',
    icon: SparklesIcon,
  },
  */
];
