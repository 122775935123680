import React, { useState, useContext, useEffect } from 'react';
import { Card, CardHeader } from 'components/ui/card';
import { Button } from "components/ui/button";
import { DescriptionDetails, DescriptionList, DescriptionTerm } from 'components/catalyst/description-list';
import { Pencil, X } from "lucide-react";
import { Input } from "components/ui/input";
import { AppContext } from 'contexts/AppContext';

const fieldConfigs = [
  { label: 'First Name', fieldName: 'firstName' },
  { label: 'Last Name', fieldName: 'lastName' },
  { label: 'Role', fieldName: 'designation' },
  { label: 'Email Address', fieldName: 'email' },
  { label: 'Phone Number', fieldName: 'phone' }
];

export default function ProfilePage() {
  const { agentProfile, editProfile, salesAgentCD } = useContext(AppContext);
  const [isEditing, setIsEditing] = useState(false);
  const [fields, setFields] = useState({});
  const [originalFields, setOriginalFields] = useState({});

  useEffect(() => {
    const initialFields = {
      firstName: agentProfile.first_name || '\u00A0',
      lastName: agentProfile.last_name || '\u00A0',
      designation: agentProfile.designation || '\u00A0',
      email: agentProfile.email || '\u00A0',
      phone: agentProfile.phone || '\u00A0'
    };
    setFields(initialFields);
    setOriginalFields(initialFields); 
  }, [agentProfile]);

  const handleInputChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  const handleSave = () => {
    const updatedProfile = {
      salesagentcd: salesAgentCD,
      first_name: fields.firstName,
      last_name: fields.lastName,
      email: fields.email,
      phone: fields.phone,
      designation: fields.designation
    };

    editProfile(updatedProfile);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setFields(originalFields); // Revert to the original field values
    setIsEditing(false);
  };

  return (
    <div className="border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <div className="flex flex-wrap pt-4">
        <Card className="w-full">
        <CardHeader>
          <div className="flex flex-row justify-between items-center">
            <h2 className="text-lg font-semibold">Profile</h2>
            {!isEditing ? (
              <Pencil onClick={() => setIsEditing(true)} className='w-4 text-gray-500 cursor-pointer' />
            ) : (
              <X onClick={handleCancel} className='w-4 text-gray-500 cursor-pointer' />
            )}
          </div>
          <DescriptionList>
            {fieldConfigs.map(({ label, fieldName }) => (
              <React.Fragment key={fieldName}>
                <div key={fieldName} >
                <DescriptionTerm className="font-semibold text-black">{label}</DescriptionTerm>
                <DescriptionDetails className="pl-0 flex items-center space-x-2 pb-0 pt-0 pr-4 sm:pb-1 sm:pt-1">
                  {!isEditing ? (
                    <span>{fields[fieldName]}</span>
                  ) : (
                    <Input
                      type="text"
                      value={fields[fieldName]}
                      onChange={(e) => handleInputChange(fieldName, e.target.value)}
                    />
                  )}
                </DescriptionDetails>
                </div>
              </React.Fragment>
            ))}
          </DescriptionList>
        </CardHeader>
        {isEditing && (
          <div className="flex justify-end p-4 pt-0">
            <Button onClick={handleCancel} size="sm" className="ml-auto" variant="outline">
              Cancel
            </Button>
            <Button onClick={handleSave} size="sm" className="ml-2">
              Save
            </Button>
          </div>
        )}
      </Card>
      </div>
    </div>
  );
}
