import React, { useState, useContext, useEffect } from 'react';
import { Text } from 'components/catalyst/text';
import { Card, CardHeader, CardContent, CardFooter } from 'components/ui/card';
import { Button } from 'components/ui/button';
import { Description, Field, Label } from 'components/catalyst/fieldset';
import { Textarea } from 'components/catalyst/textarea';
import { Listbox, ListboxLabel, ListboxOption } from 'components/catalyst/listbox';
import { AppContext } from 'contexts/AppContext';
import { toast } from 'sonner'; // Add this import
import AgentCard from './create-product-agent';
import ComplianceAgent from './create-product-agent';
import AgentsContent from './agents';

export default function AgentsPage() {
  return (
    <div className="w-full border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <AgentsContent />
    </div>
  );
}