export const getAccessToken = (testing) => {
    if (testing) return 'TestingAccessToken';

    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    return oktaTokenStorage?.accessToken?.accessToken;
};

export const getLoggedInUser = async (testing) => {
  if (testing) return { userId: 'CC106472', firstName: 'Test', lastName: 'User' };

  const retryDelay = 500; 
  const maxRetries = 5; 

  // Helper function to wait for a specified duration
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Function to attempt fetching user data with retries
  const fetchFromOktaTokenStorage = async (retries) => {
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    const preferredUsername = oktaTokenStorage?.idToken?.claims?.preferred_username;
    const name = oktaTokenStorage?.idToken?.claims?.name;

    if (preferredUsername && name) {
      const [firstPart] = preferredUsername.split('@');
      const [firstName, lastName] = name.split(' ');
      return { userId: firstPart, firstName: firstName || '', lastName: lastName || '' };
    }

    if (retries > 0) {
      await delay(retryDelay); // Wait before retrying
      return fetchFromOktaTokenStorage(retries - 1); // Retry fetching
    }

    return null; // Return null if all retries are exhausted
  };

  return await fetchFromOktaTokenStorage(maxRetries);
};

