import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';

const LoginCallback = () => {
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();
  console.log("Login Callback")
  
  useEffect(() => {
    oktaAuth.handleLoginRedirect()
      .then(() => {
        console.log("Login redirect handled successfully.");
        navigate('/email-list');
      })
      .catch(err => console.error('Error handling login redirect:', err));
  }, [oktaAuth]);

  return <div>Loading...</div>;
};

export default LoginCallback;