import { useState, useEffect } from 'react';

export function usePersistedAccount(initialValue) {
  const [account, setAccount] = useState(() => {
    const storedAccount = localStorage.getItem('selectedAccount');
    return storedAccount !== null ? storedAccount : initialValue;
  });

  useEffect(() => {
    localStorage.setItem('selectedAccount', account);
  }, [account]);

  return [account, setAccount];
}