import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Text } from 'components/catalyst/text'
import { Card, CardDescription, CardFooter, CardHeader } from 'components/ui/card'
import { ScrollArea } from "components/ui/scroll-area"
import { Separator } from "components/ui/separator"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/ui/carousel"
import { Badge } from 'components/catalyst/badge'
import { DataContext } from 'contexts/DataContext'
import { AppContext } from 'contexts/AppContext'

const tags = Array.from({ length: 50 }).map(
  (_, i, a) => `company${a.length - i}`
)

export function CustomerInsights() {
  const { aggregateInsights } = useContext(DataContext);
  const { logEvent } = useContext(AppContext);

  const badgeColors = {
    "Low Digital Revenue Pct": "teal",
    "Purchase Frequency Drop": "yellow",
    "Churn Risk": "rose",
    "Upsell Opportunity": "purple",
    "Late Payments": "red",
    "Order Inactivity": "blue",
    "Purchase Potential": "green",
    "Net New Customer": "indigo",
    "Customer Journey": "gray",
  };

  const handleClick = () => {
    logEvent('Customer Insight List', 'Arrow Clicked', null )
  };

  return (
    <div className="h-[620px] sm:mt-4">
      <Card className="h-full sm:mt-0">
        <CardHeader>
          <h2 className="text-lg font-semibold">Insights</h2>
          <CardDescription>Customers filtered by insight</CardDescription>
          <div>
            <Carousel className="w-full mx-auto">
              <CarouselContent>
                {aggregateInsights.map((insight) => (
                  <CarouselItem key={insight.insight_type}>
                    <Text className="py-3">
                      <Badge color={badgeColors[insight.insight_type] || 'gray'}>
                        {insight.insight_type}
                      </Badge>
                    </Text>
                    <ScrollArea className="w-full h-[410px] rounded-md border">
                      <div className="p-4">
                        {insight.customers.map((customer) => (
                          <React.Fragment key={customer.custcd}>
                            <div className="text-sm">
                              <Link to={`/customer/${customer.custcd}`}>
                                {customer.customer}
                              </Link>
                            </div>
                            <Separator className="my-1.5" />
                          </React.Fragment>
                        ))}
                      </div>
                    </ScrollArea>
                  </CarouselItem>
                ))}
              </CarouselContent>
              <CarouselPrevious />
              <CarouselNext />
            </Carousel>
          </div>
        </CardHeader>
        <CardFooter></CardFooter>
      </Card>
    </div>
  );
}